/* eslint-disable no-underscore-dangle */

import {SheetsRegistry} from 'jss';
import {createGenerateClassName, createMuiTheme} from '@material-ui/core/styles';
import 'typeface-abel';
import 'typeface-changa';
import 'typeface-changa-one';

// A theme with custom primary and secondary color.
// It's optional.
function getTheme (theme) {
	return createMuiTheme({
		palette: {
			type: theme.paletteType,
			primary: {
				light: '#dcffff',
				main: '#a9e9ed',
				dark: '#78b7bb',
			},
			secondary: {
				light: '#f683f1',
				main: '#8e1a8d',
				dark: '#8e1a8d',
			},
			background: {
				default: theme.paletteType === 'light' ? '#FFF' : '#202124',
			},
			text: {
				primary: theme.paletteType === 'light' ? '#000' : '#FFF',
			}
		},
		typography: {
			useNextVariants: true,
			fontFamily: '"Roboto", "sans-serif"',
			h1: {
				fontSize: 44,
				fontFamily: 'Changa one',
				letterSpacing: '0',
				lineHeight: 'normal',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			h2: {
				fontSize: 34,
				fontFamily: 'Changa',
				letterSpacing: '0.25',
				lineHeight: '1.8',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			h3: {
				fontSize: 24,
				fontFamily: 'Changa one',
				letterSpacing: '0',
				lineHeight: 'normal',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			h4: {
				fontSize: 20,
				fontFamily: 'Changa',
				letterSpacing: '0',
				lineHeight: '1.8',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			h5: {
				fontSize: 24,
				fontFamily: 'Changa',
				letterSpacing: '0.15%',
				lineHeight: '1.2',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			subtitle1: {
				fontSize: 18,
				fontFamily: 'Abel',
				letterSpacing: '0.1%',
				lineHeight: '1.2',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			h6: {
				fontSize: 36,
				fontFamily: 'Changa',
				letterSpacing: '0.15%',
				lineHeight: '1.8',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			body1: {
				fontSize: 24,
				fontFamily: 'Abel',
				letterSpacing: '0',
				lineHeight: '1',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			body2: {
				fontSize: 36,
				fontFamily: 'Abel',
				letterSpacing: '0.25%',
				lineHeight: '1',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			caption: {
				fontSize: 18,
				fontFamily: 'Abel',
				letterSpacing: '0.4%',
				lineHeight: '1.2',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
			},
			button: {
				fontSize: 22,
				fontFamily: 'Changa',
				letterSpacing: '0.75%',
				lineHeight: '1.8',
				color: theme.paletteType === 'light' ? '#000' : '#FFF',
				textDecoration: 'none',
			},
		},
	});
}


const theme = getTheme({
	paletteType: 'light',
});
const themeDark = getTheme({
	paletteType: 'dark',
});

function createPageContext () {
	return {
		theme,
		themeDark,
		// This is needed in order to deduplicate the injection of CSS in the page.
		sheetsManager: new Map(),
		// This is needed in order to inject the critical CSS.
		sheetsRegistry: new SheetsRegistry(),
		// The standard class name generator.
		generateClassName: createGenerateClassName(),
	};
}

export default function getPageContext () {
	// Make sure to create a new context for every server-side request so that data
	// isn't shared between connections (which would be bad).
	if (!process.browser) {
		return createPageContext();
	}

	// Reuse context on the client-side.
	if (!global.__INIT_MATERIAL_UI__) {
		global.__INIT_MATERIAL_UI__ = createPageContext();
	}

	return global.__INIT_MATERIAL_UI__;
}
